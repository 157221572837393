.App {
  font-family: -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  background: #F0F2F5;
}


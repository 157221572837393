.SwiziNewsEditor {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  font: inherit;
  flex-direction: column;
}

.SwiziNewsEditor__Topbar {
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
}

.SwiziNewsEditor__Settings,
.SwiziNewsEditor__Preview {
  flex: 1;
  background: #fafafa;
  position: relative;
}

.SwiziNewsEditor__Main {
  flex: 2;
  background: white;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
}

.SwiziNewsEditor__Button--Main {
  background: var(--SwiziNewsEditor__Primary);
  margin: 16px;
  padding: 8px 16px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  color: var(--SwiziNewsEditor__Text);
  width: 150px;
}

.SwiziNewsEditor__Popup--BG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.SwiziNewsEditor__Library {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  transition: 0.25s all ease-in;
  overflow: auto;
  display: flex;
  top: 0;
}

.SwiziNewsEditor__Library--Open {
  left: 0;
  z-index: 9000;
  background: var(--SwiziNewsEditor__Text);
  box-shadow: inset -1px 0 1px rgba(0, 0, 0, 0.125);
}

.SwiziNewsEditor__Settings {
  box-shadow: inset -1px 0 1px rgba(0, 0, 0, 0.125);
  overflow: auto;
}

.ios-switch {
  display: none;
  position: relative;
}

.ios-switch + label .small {
  font-size: 0.8em;
  color: #999;
  line-height: 1.5em;
  display: block;
}

.ios-switch + label {
  padding: 10px;
  padding-left: 0px;
  text-align: left !important;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  margin-top: 8px;
  font-size: 12px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  user-select: none;
}

.ios-switch + label span.sw {
  display: inline-block;
  width: 46px;
  height: 24px;
  float: right;
  border-radius: 16px;
  margin-right: 10px;
  border: 2px solid #e3e3e3;
  background-color: #fff;
  transition: all ease 300ms;
  position: relative;
  cursor: pointer;
}

.ios-switch + label span.sw:before {
  content: "";
  position: absolute;
  background-color: #fff;
  margin-top: -2px;
  margin-left: 0px;
  height: 27px;
  width: 27px;
  border-radius: 15px;
  transition: all ease 300ms;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}

.ios-switch:checked + label span.sw {
  background-color: var(--SwiziNewsEditor__Primary);
  border: 2px solid var(--SwiziNewsEditor__Primary);
}

.ios-switch:checked + label span:before {
  margin-left: 19px;
  background-color: #fff;
}

img.SwiziNewsEditor__SettingsItem--LibraryItem {
  object-fit: cover;
  width: 150px;
  max-height: 100%;
  min-height: 150px;

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  margin: 10px;
  transition: 0.25s all ease-in;
  cursor: pointer;
}

.SwiziNewsEditor__SettingsItem--LibraryItemSelected {
  box-shadow: 5px 5px 10px -4px var(--SwiziNewsEditor__Primary) !important;
}

img.SwiziNewsEditor__SettingsItem--ImageSelected {
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.SwiziNewsEditor__SettingsItem--LibraryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 0;
}

.SwiziNewsEditor__Preview--Container-Header {
  height: 250px;
  background: white;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.SwiziNewsEditor__Preview-Container-Header-Title {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  bottom: 60px;
  left: 16px;
  opacity: 1;
}

.SwiziNewsEditor__Preview-Container-Header-Title.NewsSection {
  position: relative;
  bottom: unset;
  left: unset;
  font-size: 17px;
  margin: 16px 16px 0 16px;
  color: var(--SwiziNewsEditor__ContentColor);
}

.SwiziNewsEditor__Preview-Container-HeaderDate.NewsSection {
  font-size: 10px;
  font-weight: 400;
  margin: 11px 16px 0 16px;
}

.SwiziNewsEditor__Preview-Container-Header-Subtitle.NewsSection {
  font-size: 14px;
  font-weight: 700;
  margin: 22px 16px 0 16px;
}

.SwiziNewsEditor__Preview--Container-Header-Image.NewsSection img {
  max-width: 100%;
  margin-top: 16px;
}

.SwiziNewsEditor__Preview-Container-Body-HTML-Content.NewsSection {
  margin: 16px 16px 32px 16px;
  border-top: none;
  font-size: 14px;
  color: unset;
}

.SwiziNewsEditor__Preview {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.125);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: fit-content;
}

.SwiziNewsEditor__SettingsItem {
  padding: 16px;
  padding-top: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  overflow: auto;
}

.SwiziNewsEditor__SettingsItem:last-child {
  margin-bottom: 200px;
}

.SwiziNewsEditor__SettingsItem--Label {
  font-weight: 500;
  color: var(--SwiziNewsEditor__Primary);
  position: sticky;
  backdrop-filter: top;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  padding: 16px 0 4px 0;
}

.SwiziNewsEditor__SettingsItem--Select > input[type="text"],
.SwiziNewsEditor__SettingsItem--Select > input[type="number"],
.SwiziNewsEditor__SettingsItem--Select > textarea {
  font-family: sans-serif;
  height: 38px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 16px;
  padding: 0 8px;
  /* cursor: pointer; */
}

.SwiziNewsEditor__SettingsItem--Select > textarea {
  resize: none;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  padding: 0.5rem;
}

.SwiziNewsEditor__SettingsItem--LibrarySelect {
  padding: 8px;
  /* text-align: center; */
  background: white;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: rgba(0, 0, 0, 0.5);
  transition: 0.25s all ease-in;
  width: 100%;
}

.SwiziNewsEditor__SettingsItem--LibrarySelect:hover {
  color: rgba(0, 0, 0, 1);
}

.SwiziNewsEditor__Library--Close {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.SwiziNewsEditor__Library--Close svg {
  color: var(--SwiziNewsEditor__Primary);
  stroke-width: 2px;
}

.SwiziNewsEditor__Preview--Container {
  width: 375px;
  height: 812px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  position: relative;
  margin-top: 8px;
}

.SwiziNewsEditor__Preview-Container-Body {
  height: auto;
  border-radius: 13px 13px 0 0;
  margin-top: -50px;
  z-index: 90000;
  background: white;
  /* animation: slide-in-bottom 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
  overflow: auto;
  flex: 2;
}

.SwiziNewsEditor__Preview-Container-Body-Schedule {
  display: flex;
  padding: 29px 41px 0;
}

.SwiziNewsEditor__Preview-Container-Body-Schedule-Item {
  flex: 2 1;
  display: flex;
  align-items: center;
}

.SwiziNewsEditor__Preview-Container-Body-Icon {
  width: 16px;
  min-width: 16px;
}

.SwiziNewsEditor__Preview-Container-Body-Schedule-Item-Label {
  color: #444444;
  margin-left: 8px;
  line-height: 17px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  font-size: 12px;
  /* padding-top: 9Px; */
  /* font-weight: bold; */
  color: var(--SwiziNewsEditor__ContentColor);
}

.SwiziNewsEditor__Main-Type {
  max-width: fit-content;
  margin: auto;
  margin: 16px auto;
  background: var(--SwiziNewsEditor__Primary);
  color: var(--SwiziNewsEditor__Text);
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.SwiziNewsEditor__Edit-Container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: fit-content;
  margin-left: 16px;
  cursor: pointer;
}

.SwiziNewsEditor__Edit-Text {
  margin-left: 8px;
  font-size: 14px;
}

.SwiziNewsEditor__Preview-Container-Body-HTML-Content {
  margin: 0 16px;
  margin-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  color: var(--SwiziNewsEditor__ContentColor);
}

#UNIQUE_ID_OF_DIV {
  flex: 2;
  width: calc(100% - 32px) !important;
  margin: 16px;
}

.SwiziNewsEditor__Preview-Container-Body-HTML-Content img {
  max-width: 100%;
  width: 100%;
}

.SwiziNewsEditor__SettingsItem--Select-Label {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 8px;
}

.SwiziNewsEditor__SettingsItem--Button {
  margin-right: 8px;
  padding: 8px;
  transition: 0.25s all ease-in;
  cursor: pointer;
}

.SwiziNewsEditor__SettingsItem--Select-Flex {
  flex: 1;
}

.SwiziNewsEditor__SettingsItem--Button-Selected {
  background: var(--SwiziNewsEditor__Primary);
  color: var(--SwiziNewsEditor__Text);
  font-weight: bold;
  border-radius: 5px;
}

.SwiziNewsEditor__SettingsItem--SubLabel {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 12px;
}

.SwiziNewsEditor__SettingsItem--Buttons {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.SwiziNewsEditor__Selection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SwiziNewsEditor__LoaderContainer--Label {
  margin: 32px 8px;
  font-size: 18px;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.SwiziNewsEditor__LoaderContainer--SVG {
  width: 400px;
  height: auto;
  margin-bottom: 12px;
}

.SwiziNewsEditor__LoaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SwiziNewsEditor__Menu-Title {
  text-align: center;
  font-size: 2.5em;
  font-weight: 300;
  margin-bottom: 40px;
  margin-top: 20px;
}

.SwiziNewsEditor__ContentsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}

.SwiziNewsEditor__ContentItem {
  width: calc(100% / 7 - 32px);
  min-width: 200px;
  max-width: calc(100% / 7 - 32px);
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  display: flex;
  margin: 16px;
  flex-direction: column-reverse;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.SwiziNewsEditor__ContentItem--Body-SubTitle {
  color: var(--SwiziNewsEditor__Text);
  font-weight: 600;
  font-size: 14px;
  z-index: 4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.SwiziNewsEditor__SettingsItem--Select.Multiple {
  display: flex;
  width: 100%;
  align-items: center;
}

.SwiziNewsEditor__SettingsItem--Select.Multiple .SwiziNewsEditor__SettingsItem--SubLabel {
  margin-bottom: 0px;
  margin: 0 16px;
}

.SwiziNewsEditor__spin {
  animation: spin 1s linear infinite;
}

.SwiziNewsEditor__ContentItem--Body {
  /* background: rgba(0, 0, 0, 0.6); */
  width: 100%;
  height: auto;
  min-height: 100px;
  border-radius: 5px 5px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.25s all ease-in;
}

.SwiziNewsEditor__Topbar--Back-Container {
  width: 150px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
}

.SwiziNewsEditor__SettingsItem--Select > input[type="text"]:focus,
.SwiziNewsEditor__SettingsItem--Select > textarea:focus {
  outline-offset: -2px;
  outline: var(--SwiziNewsEditor__Primary) auto 5px;
}

.SwiziNewsEditor__SettingsItem--Separator {
  width: 100%;
  /* width: 1px; */
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}

.SwiziNewsEditor__ContentItem--Body-BG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--SwiziNewsEditor__Primary);
  opacity: 0.75;
  z-index: 2;
  border-radius: 5px 5px;
}

.SwiziNewsEditor__Topbar--Back-Label {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.5);
}

.SwiziNewsEditor__ContentItem--Body-Title {
  color: var(--SwiziNewsEditor__Text);
  font-weight: 600;
  font-size: 18px;
  z-index: 4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SwiziNewsEditor__ContentItem--Body-Date {
  color: var(--SwiziNewsEditor__Text);
  z-index: 4;
}

svg.SwiziNewsEditor__ContentItem--Body-Actions-Item {
  color: var(--SwiziNewsEditor__Text);
  z-index: 4;
  width: 24px;
  height: 24px;
  flex: 2;
  cursor: pointer;
}

.SwiziNewsEditor__Tppnar--Title {
  flex: 2;
  text-align: center;
  font-weight: bold;
  color: var(--SwiziNewsEditor__Primary);
  font-size: 18px;
}

.SwiziNewsEditor__ContentItem--Body-Actions-Container {
  cursor: pointer;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 0.25s all ease-in;
  border-radius: 5px;
  margin-top: 8px;
  z-index: 4;
}

.SwiziNewsEditor__ContentItem--New {
  cursor: pointer;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  transition: 0.25s all ease-in;
  color: var(--SwiziNewsEditor__Primary);
  background: var(--SwiziNewsEditor__Text);
  position: relative;
}

.SwiziNewsEditor__ContentItem--New-Container {
  z-index: 90000000;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  color: var(--SwiziNewsEditor__Primary);
}

.SwiziNewsEditor__ContentItem--New--BG {
  /* position: absolute;
  left: 0; */
  width: 80%;
  height: auto;
  margin-bottom: 8px;
}

.SwiziNewsEditor__ContentItem--New-SVG {
  height: 32px;
  margin-right: 8px;
  color: inherit;
  transition: 0.25s all ease-in;
}

.SwiziNewsEditor__ContentItem--New:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.125);
  background: var(--SwiziNewsEditor__Primary);
  color: var(--SwiziNewsEditor__Text);
}

.SwiziNewsEditor__ContentItem--New:hover svg {
  color: var(--SwiziNewsEditor__Text);
}

.SwiziNewsEditor__ContentItem--New:hover .SwiziNewsEditor__ContentItem--Label {
  color: var(--SwiziNewsEditor__Text);
  transition: 0.25s all ease-in;
}

.SwiziNewsEditor__ContentItem--Label {
  font-size: 18px;
}

.SwiziNewsEditor__Topbar--Back {
  color: var(--SwiziNewsEditor__Primary);
  width: 18px;
  cursor: pointer;
}

.m-left {
  --translate: -50px;
}

.m-left,
.m-right {
  animation: 1s moveX alternate infinite paused;
}

@keyframes moveX {
  to {
    transform: translateX(-83px) translateY(60px);
  }
}

.SwiziNewsEditor__ContentItem--New:hover * {
  animation-play-state: running;
}

.SwiziNewsEditor__ContentItem--Body-Actions-Container-Edit:hover {
  background: rgba(255, 255, 255, 0.5);
}

.SwiziNewsEditor__ContentItem--Body-Actions-Container-Delete:hover {
  background: rgba(255, 0, 0, 0.5);
}

.SwiziNewsEditor__ContentItem--Body:hover .SwiziNewsEditor__ContentItem--Body-Actions {
  opacity: 1;
  user-select: initial;
}

.SwiziNewsEditor__Uploading-State {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000000;
  background: rgba(0, 0, 0, 0.5);
  /* filter: blur(5px); */
  flex-direction: column;
}

.SwiziNewsEditor__ContentItem--Body-Actions {
  flex: 2;
  display: flex;
  align-items: center;
  opacity: 0;
  user-select: none;
  transition: 0.25s all ease-in;
}

/* QUILL */
.quill {
  background: white;
  flex: 2;
  display: flex;
  flex-direction: column;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.ql-container.ql-snow {
  border: none;
  flex: 2;
  overflow: auto;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: 32px;
  height: 32px;
}

/* REACT DATE TIME PICKER */
.react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled {
  width: 100%;
}

.react-calendar {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
}

.react-datetime-picker__calendar.react-datetime-picker__calendar--open {
  width: 100%;
}

.react-datetime-picker__inputGroup {
  padding: 8px;
  /* border-radius: 5px; */
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background: white;
  border-radius: 5px;
}

.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  width: 100%;
}

.react-calendar__tile--active {
  background: var(--SwiziNewsEditor__Primary);
  color: var(--SwiziNewsEditor__Text);
}

.react-calendar__tile--hasActive {
  background: var(--SwiziNewsEditor__Primary);
  color: var(--SwiziNewsEditor__Text);
}

/* .ql-toolbar.ql-snow {
  border-radius: 5px 5px 0 0;
}
.ql-container.ql-snow {
  border-radius: 0 0 5px 5px;
} */

/* LOADER CSS */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--SwiziNewsEditor__Primary);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.SwiziNewsEditor__fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.SwiziNewsEditor__fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.SwiziNewsEditor__Settings--Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  font: inherit;
  max-height: calc(100% - 64px);
}

.SwiziNewsEditor__Settings--Container.Reminder {
  max-width: 600px;
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.SwiziNewsEditor__Button--Main.SwiziNewsEditor__Button--Main-Disabled {
  cursor: inherit;
  background: var(--SwiziNewsEditor__Text);
  color: var(--SwiziNewsEditor__Primary);
  border: 1px solid;
}

.SwiziNewsEditor__Button--Main-Unavailabke {
  font-size: 12px;
  color: var(--SwiziNewsEditor__Primary);
  font-style: italic;
}

.SwiziNewsEditor__Uploading-State-Body {
  padding: 32px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  text-align: center;
  animation: scale-in-center 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.SwiziNewsEditor__Button--Main-Container {
  display: flex;
}

.SwiziNewsEditor__SettingsItem--LibraryItem {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  margin: 10px;
  color: var(--SwiziNewsEditor__Primary);
  background: var(--SwiziNewsEditor__Text);
  position: relative;
  transition: 0.25s all ease-in;
}

.SwiziNewsEditor__SettingsItem--LibraryItem:hover {
  background: var(--SwiziNewsEditor__Primary);
  color: var(--SwiziNewsEditor__Text);
}

.SwiziNewsEditor__ContentItem--Input {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.SwiziNewsEditor__SettingsItem--Label {
  z-index: 0;
}

.SwiziNewsEditor__SettingsItem {
  overflow: unset;
}

.SwiziNewsEditor__SettingsItem--CropContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  z-index: 200000000;
  display: flex;
  align-content: center;
  justify-content: center;
}

.SwiziNewsEditor__SettingsItem--CropContainer > div {
  max-width: 1080px;
  overflow: visible;
}

.SwiziNewsEditor__SettingsItem--CropContainer--ActionButtons {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.SwiziNewsEditor__ContentItem--Topics {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 8px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4px;
}

.SwiziNewsEditor__ContentItem--Topic {
  font-size: 12px;
  padding: 0 6px;
  line-height: 20px;
  border-radius: 10px;
  color: var(--SwiziNewsEditor__Text);
  background: var(--SwiziNewsEditor__Primary);
  margin-right: 4px;
  margin-bottom: 4px;
}
.SwiziNewsEditor__ContentItem--Topic:last-child {
  margin-right: 0;
}

.SwiziNewsEditor__ContentItem--NewTopic {
  position: absolute;
  width: 320px;
  height: calc(100% - 32px);
  top: 0;
  left: 0;
  padding: 16px;
  background: var(--SwiziNewsEditor__Text);
  box-shadow: 0px 0 6px rgb(0 0 0 / 13%);
  transform: translateX(-352px);
  transition: transform ease-in-out 0.3s;
}

.SwiziNewsEditor__ContentItem--NewTopic[data-show="true"] {
  transform: translateX(0px);
}

.SwiziNewsEditor__SettingsItem--TopicEdit .SwiziNewsEditor__ContentItem--Body-Actions-Item,
.SwiziNewsEditor__SettingsItem--TopicDelete .SwiziNewsEditor__ContentItem--Body-Actions-Item {
  color: var(--SwiziNewsEditor__Primary);
}

.SwiziNewsEditor__SettingsItem--TopicEdit:not(:last-child) {
  margin-right: 8px;
}

.SwiziNewsEditor__SettingsItem--TopicEdit label,
.SwiziNewsEditor__SettingsItem--TopicEdit label span {
  margin: 0 !important;
  padding: 0;
}

.SwiziNewsEditor__SettingsItem--TopicName {
  flex-grow: 1;
  transition: opacity 0.3s ease-in-out;
}

.SwiziNewsEditor__SettingsItem--TopicName .placeholder {
  color: rgba(0, 0, 0, 0.33);
}

.SwiziNewsEditor__SettingsItem--TopicName[data-active="false"] {
  opacity: 0.5;
}

.SwiziNewsEditor__SettingsItem.SwiziNewsEditor__ExistingTopics
  .SwiziNewsEditor__SettingsItem--Label {
  background: var(--SwiziNewsEditor__Text);
}

.SwiziNewsEditor__SettingsItem--TopicReorder {
  margin-right: 10px;
  margin-top: -2px;
}

.SwiziNewsEditor__SettingsItem--TopicReorder:hover {
  cursor: pointer;
}

.SwiziNewsEditor__SettingsItem--TopicEdit svg,
.SwiziNewsEditor__SettingsItem--TopicDelete svg {
  width: 18px;
}

.SwiziNewsEditor__AddTopic {
  border-bottom: none;
}

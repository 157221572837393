:root {
    --SwiziNewsViewer-accent-color: #F18815;
    --SwiziNewsViewer-topic-title-color: #5C6063;
    --SwiziNewsViewer-topic-title-big-color: #023466;
    --SwiziNewsViewer-text-color: #000A32;
    --SwiziNewsViewer-text-background: #ffffff;
}

.swiziNews {
    width: 100%;
    max-width: 1300px;
    margin: 0;
    padding-bottom: 30px;
}

.closeSelected {
    position: absolute;
    top: 4px;
    padding: 5px 9px;
    border-radius: 8px;
    background: var(--SwiziNewsViewer-topic-title-color);
    color: var(--SwiziNewsViewer-text-background);
    font-size: 14px;
    transition: background 0.3s ease-in-out;
}

.closeSelected:hover {
    cursor: pointer;
    background: var(--SwiziNewsViewer-accent-color);
}

.closeSelected + .topicTitle {
    font-size: 48px;
    color: var(--SwiziNewsViewer-text-color);
}

/* Topics */
.topicTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 44px;
    font-size: 20px;
    font-weight: 700;
    color: var(--SwiziNewsViewer-topic-title-color);
}

.topicTitle .topicShowMore {
    color: var(--SwiziNewsViewer-accent-color);
    display: flex;
    align-items: flex-end;
}

.topicTitle .topicShowMore:hover {
    cursor: pointer;
}

.trendings[data-preview="true"] .topicTitle {
    color: var(--SwiziNewsViewer-topic-title-big-color);
}

.topicContents, .trendings[data-preview="true"] .topicContents {
    width: 100%;
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.topicContents.topics2, .trendings[data-preview="true"] .topicContents.topics2 {
    justify-content: flex-start;
}

/* Contents */
.content, .trendings[data-preview="true"] .topicContents .content {
    width: calc(33% - 16px);
    min-width: 180px;
    /* max-height: 285px; */
    height: 305px;
    position: relative;
    /* overflow: hidden; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.content:hover {
    cursor: pointer;
}

.topicContents.topics2 .content, .trendings[data-preview="true"] .topicContents.topics2 .content {
    margin-right: 32px;
}

.contentMedia, .trendings[data-preview="true"] .topicContents .content .contentMedia {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 225px;
    overflow: hidden;
}

.customMedia {
    width: 60%;
}

.contentMedia img,  .trendings[data-preview="true"] .topicContents .content .contentMedia img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 100%;
}

.contentTitle {
    padding: 8px;
    font-size: 21px;
    font-weight: 700;
    color: var(--SwiziNewsViewer-text-background);
    background: var(--SwiziNewsViewer-text-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

.contentDate {
    padding-left: 8px;
    padding-bottom: 10px;
    font-size: 16px;
    color: var(--SwiziNewsViewer-text-background);
    background: var(--SwiziNewsViewer-text-color);
}

.customFont {
    color: var(--SwiziNewsViewer-text-color);
    background: var(--SwiziNewsViewer-text-background);
}


.htmlContentAndOther {
    width: 100%;
    display: flex;
    flex-direction: row;
    order: 4;
}

.contentOtherInSameTopics {
    width: 100%;
    padding-left: 32px;
    /* overflow: hidden; */
}

/* Content fullsized */ 
.content[data-fullsize="true"] {
    width: 100%;
    max-height: unset;
    height: unset;
    border-radius: unset;
    display: flex;
    flex-direction: column;
}

.content[data-fullsize="true"]:hover {
    cursor: unset;
}

.content[data-fullsize="true"] > .contentTitle {
    order: 0;
    /* margin-top: 32px; */
    padding-left: 0;
    font-size: 48px;
    background: unset;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
}

.content[data-fullsize="true"] > .contentDate {
    order: 1;
    padding-left: 0;
    padding-bottom: 0;
    background: unset;
}

.content[data-fullsize="true"] > .contentSubTitle {
    order: 2;
    margin-top: 26px;
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 700;
    color: var(--SwiziNewsViewer-text-color);
}

.content[data-fullsize="true"] > .contentMedia {
    order: 3;
    max-height: 350px;
}

.content[data-fullsize="true"] > .contentMedia img {
    aspect-ratio: unset;
}

.content[data-fullsize="true"] > .htmlContentAndOther .contentHTML {
    max-width: calc(66% - 80px);
    min-width: calc(66% - 80px);
    width: calc(66% - 80px);
    padding: 32px 40px;
    background: var(--SwiziNewsViewer-text-background);
    font-size: 18px;
    font-weight: 400;
    color: var(--SwiziNewsViewer-text-color);
}

.content[data-fullsize="true"] > .htmlContentAndOther .contentHTML img {
    max-width: 100%;
}

.content[data-fullsize="true"] > .htmlContentAndOther .contentOtherInSameTopics .topicContents {
    flex-direction: column;
}

.content[data-fullsize="true"] > .htmlContentAndOther .contentOtherInSameTopics .topicContents .content {
    width: 100%;
    margin-top: 16px;
}

.content[data-fullsize="true"] > .htmlContentAndOther .contentOtherInSameTopics .topicContents .content:first-child {
    margin-top: 0;
}

.contentEvent .title {
    font-size: 20px;
    font-weight: 700;
    color: var(--SwiziNewsViewer-accent-color);
    margin-bottom: 7px;
}

.contentEvent .title ~ div {
    font-size: 18px;
    font-weight: 700;
    color: var(--SwiziNewsViewer-text-color);
}

.contentEvent .eventMap {
    margin-top: 8px;
    margin-bottom: 32px;
    width: 100%;
    height: 300px;
    pointer-events: none;
}

@media screen and (max-device-width: 800px) {
    .swiziNews {
        width: calc(100% - 32px);
        margin: 0 16px;
    }

    .content, .trendings[data-preview="true"] .topicContents .content {
        margin-bottom: 16px;
        height: unset;
    }

    .contentMedia, .trendings[data-preview="true"] .topicContents .content .contentMedia {
        height: 100px;
        max-height: unset;
    }

    .content[data-fullsize="true"] > .htmlContentAndOther .contentHTML {
        max-width: calc(100% - 80px);
        min-width: calc(100% - 80px);
        width: calc(100% - 80px);
    }

    .content[data-fullsize="true"] > .htmlContentAndOther .contentOtherInSameTopics {
        padding-left: 0;
    }

    .htmlContentAndOther {
        flex-direction: column;
    }
}

@media screen and (min-device-width: 800px) {
    /* Trendings */
    .trendings .topicTitle {
        color: var(--SwiziNewsViewer-accent-color);
    }
    
    .trendings .topicTitle .topicShowMore {
        font-size: 20px;
    }
    
    .trendings .topicContents {
        display: inline-block;
    }
    
    .trendings .content {
        float: left;
    }
    
    .trendings .content {
        width: calc(48%);
        max-height: 586px;
        /* margin-right: 32px; */
    }
    
    .trendings .content:first-child .contentMedia {
        /* max-height: 526px; */
    }
    
    .trendings .content:first-child .contentMedia img {
        aspect-ratio: 1.6;
    }
    
    .trendings .content:last-child {
        /* margin-top: 16px; */
    }
    
    .trendings[data-preview="true"] .topicTitle {
        font-size: 36px;
    }
    
    .trendings[data-preview="true"] .topicContents .content:first-child {
        /* margin-right: unset; */
    }
    
    .trendings[data-preview="true"] .topicContents .content:last-child {
        margin-top: unset;
    }    
}

/* LOADER CSS */
.swiziNewsLoader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9000000;
    background: rgba(255, 255, 255, 1);
    /* backdrop-filter: blur(5px); */
    flex-direction: column;
    /* transition: opacity 0.3s ease-in-out; */
    opacity: 0;
    pointer-events: none;
}

.swiziNewsLoader[data-show="true"] {
    opacity: 1;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--SwiziNewsViewer-accent-color);
    margin: -4px 0 0 -4px;
  }
  
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }